import * as types from './types'
import { EventsProxy } from 'services'
import { showSnackbarError } from 'state/modules/app'

export const pagedListAttempt = (reqParams) => ({
  type: types.PAGEDLIST_ATTEMPT,
  reqParams
})

export const pagedListSuccess = (pagedList) => ({
  type: types.PAGEDLIST_SUCCESS,
  pagedList
})

export const pagedListFailure = (error) => ({
  type: types.PAGEDLIST_FAILURE,
  error
})

export const loadEventsWithComboAttempt = () => ({
  type: types.LOAD_EVENTS_WITH_COMBO_ATTEMPT
})

export const loadEventsWithComboSuccess = (eventsWithCombo) => ({
  type: types.LOAD_EVENTS_WITH_COMBO_SUCCESS,
  eventsWithCombo
})

export const loadEventsWithComboFailure = (error) => ({
  type: types.LOAD_EVENTS_WITH_COMBO_FAILURE,
  error
})

export const loadEventsAttempt = () => ({
  type: types.LOAD_EVENTS_ATTEMPT
})

export const loadEventsSuccess = (events) => ({
  type: types.LOAD_EVENTS_SUCCESS,
  events
})

export const loadEventsFailure = (error) => ({
  type: types.LOAD_EVENTS_FAILURE,
  error
})

export const loadEventAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadEventSuccess = (eventObj) => ({
  type: types.LOAD_SUCCESS,
  eventObj
})

export const loadEventFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (eventObj) => ({
  type: types.CREATE_SUCCESS,
  eventObj
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateSuccess = (eventObj) => ({
  type: types.UPDATE_SUCCESS,
  eventObj
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const enableAttempt = () => ({
  type: types.ENABLE_ATTEMPT
})

export const enableSuccess = (eventObj) => ({
  type: types.ENABLE_SUCCESS,
  eventObj
})

export const enableFailure = (error) => ({
  type: types.ENABLE_FAILURE,
  error
})

export const disableAttempt = () => ({
  type: types.DISABLE_ATTEMPT
})

export const disableSuccess = (eventObj) => ({
  type: types.DISABLE_SUCCESS,
  eventObj
})

export const disableFailure = (error) => ({
  type: types.DISABLE_FAILURE,
  error
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteSuccess = () => ({
  type: types.DELETE_SUCCESS
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const sendNotificationAttempt = () => ({
  type: types.SEND_NOTIFICATION_ATTEMPT
})

export const sendNotificationSuccess = () => ({
  type: types.SEND_NOTIFICATION_SUCCESS
})

export const sendNotificationFailure = (error) => ({
  type: types.SEND_NOTIFICATION_FAILURE,
  error
})

export const loadImagesAttempt = () => ({
  type: types.LOAD_IMAGES_ATTEMPT
})

export const loadImagesSuccess = (images) => ({
  type: types.LOAD_IMAGES_SUCCESS,
  images
})

export const loadImagesFailure = (error) => ({
  type: types.LOAD_IMAGES_FAILURE,
  error
})

export const setDefaultImageAttempt = () => ({
  type: types.SET_DEFAULT_IMAGE_ATTEMPT
})

export const setDefaultImageSuccess = () => ({
  type: types.SET_DEFAULT_IMAGE_SUCCESS
})

export const setDefaultImageFailure = (error) => ({
  type: types.SET_DEFAULT_IMAGE_FAILURE,
  error
})

export const addImageAttempt = () => ({
  type: types.ADD_IMAGE_ATTEMPT
})

export const addImageSuccess = () => ({
  type: types.ADD_IMAGE_SUCCESS
})

export const addImageFailure = (error) => ({
  type: types.ADD_IMAGE_FAILURE,
  error
})

export const deleteImageAttempt = () => ({
  type: types.DELETE_IMAGE_ATTEMPT
})

export const deleteImageSuccess = () => ({
  type: types.DELETE_IMAGE_SUCCESS
})

export const deleteImageFailure = (error) => ({
  type: types.DELETE_IMAGE_FAILURE,
  error
})

export const reset = () => ({
  type: types.RESET
})

export const resetTable = () => ({
  type: types.RESET_TABLE
})

export const resetActions = () => ({
  type: types.RESET_ACTIONS
})

export const resetParamsEvents = () => ({
  type: types.RESET_PARAMS
})

//tickets
export const pagedListTicketAttempt = () => ({
  type: types.PAGEDLIST_TICKET_ATTEMPT
})

export const pagedListTicketSuccess = (ticketList) => ({
  type: types.PAGEDLIST_TICKET_SUCCESS,
  ticketList
})

export const pagedListTicketFailure = (error) => ({
  type: types.PAGEDLIST_TICKET_FAILURE,
  error
})

export const loadTicketAttempt = () => ({
  type: types.LOAD_TICKET_ATTEMPT
})

export const loadTicketSuccess = (ticket) => ({
  type: types.LOAD_TICKET_SUCCESS,
  ticket
})

export const loadTicketFailure = (error) => ({
  type: types.LOAD_TICKET_FAILURE,
  error
})

export const loadTicketWithComboAttempt = () => ({
  type: types.LOAD_TICKET_WITH_COMBO_ATTEMPT
})

export const loadTicketWithComboSuccess = (ticket) => ({
  type: types.LOAD_TICKET_WITH_COMBO_SUCCESS,
  ticket
})

export const loadTicketWithComboFailure = (error) => ({
  type: types.LOAD_TICKET_WITH_COMBO_FAILURE,
  error
})

export const createTicketAttempt = () => ({
  type: types.CREATE_TICKET_ATTEMPT
})

export const createTicketSuccess = (ticket) => ({
  type: types.CREATE_TICKET_SUCCESS,
  ticket
})

export const createTicketFailure = (error) => ({
  type: types.CREATE_TICKET_FAILURE,
  error
})

export const updateTicketAttempt = () => ({
  type: types.UPDATE_TICKET_ATTEMPT
})

export const updateTicketSuccess = (ticket) => ({
  type: types.UPDATE_TICKET_SUCCESS,
  ticket
})

export const updateTicketFailure = (error) => ({
  type: types.UPDATE_TICKET_FAILURE,
  error
})

export const deleteTicketAttempt = () => ({
  type: types.DELETE_TICKET_ATTEMPT
})

export const deleteTicketSuccess = (ticket) => ({
  type: types.DELETE_TICKET_SUCCESS,
  ticket
})

export const deleteTicketFailure = (error) => ({
  type: types.DELETE_TICKET_FAILURE,
  error
})

export const ticketTypeSet = (ticketType) => ({
  type: types.SET_TICKET_TYPE,
  ticketType
})

export const getTicketTypesReportAttempt = () => ({
  type: types.GET_TICKET_TYPES_REPORT_ATTEMPT
})
export const getTicketTypesReportSuccess = (report) => ({
  type: types.GET_TICKET_TYPES_REPORT_SUCCESS,
  report
})
export const getTicketTypesReportFailure = (error) => ({
  type: types.GET_TICKET_TYPES_REPORT_FAILURE,
  error
})

export const shareUrlAttempt = () => ({
  type: types.SHARE_URL_ATTEMPT
})

export const shareUrlSuccess = (url) => ({
  type: types.SHARE_URL_SUCCESS,
  url
})

export const shareUrlFailure = (error) => ({
  type: types.SHARE_URL_FAILURE,
  error
})

export const resetTickets = () => ({
  type: types.RESET_TICKETS
})

export const loadRuleTicketsAttempt = () => ({
  type: types.LOAD_RULE_TICKETS_ATTEMPT
})

export const loadRuleTicketsSuccess = (tickets) => ({
  type: types.LOAD_RULE_TICKETS_SUCCESS,
  tickets
})

export const loadRuleTicketsError = (error) => ({
  type: types.LOAD_RULE_TICKETS_FAILURE,
  error
})

export const loadPage =
  ({ searchParams, pageNumber, pageSize, orderBy, order }) =>
  async (dispatch) => {
    try {
      dispatch(
        pagedListAttempt({ searchParams, order, orderBy, pageSize, pageNumber })
      )

      const proxy = new EventsProxy()
      const pagedList = await proxy.get(
        searchParams,
        pageNumber,
        pageSize,
        orderBy,
        order
      )

      dispatch(pagedListSuccess(pagedList))
    } catch (error) {
      dispatch(pagedListFailure(error))
      throw error
    }
  }

export const loadEvents = () => async (dispatch) => {
  try {
    dispatch(loadEventsAttempt())

    const proxy = new EventsProxy()
    const all = await proxy.getAll()
    dispatch(loadEventsSuccess(all))
    return true
  } catch (error) {
    dispatch(loadEventsFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const loadLastEvents = (quantity) => async (dispatch) => {
  try {
    dispatch(loadEventsAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getLastEvents(quantity)
    dispatch(loadEventsSuccess(response))
    return response
  } catch (error) {
    dispatch(loadEventsFailure(error))
    throw error
  }
}

export const loadEventsWithCombo = () => async (dispatch) => {
  try {
    dispatch(loadEventsWithComboAttempt())

    const proxy = new EventsProxy()
    const all = await proxy.getEventsWithCombo()
    dispatch(loadEventsWithComboSuccess(all))
  } catch (error) {
    dispatch(loadEventsWithComboFailure(error))
    throw error
  }
}

export const loadEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getById(eventId)

    dispatch(loadEventSuccess(response))
    return response
  } catch (error) {
    dispatch(loadEventFailure(error))
    throw error
  }
}

export const loadEventManager = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getByIdManager(eventId)

    dispatch(loadEventSuccess(response))
    return true
  } catch (error) {
    dispatch(loadEventFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const create = (eventObj) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.post(eventObj)

    dispatch(createSuccess(response))
    return response
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const update = (eventId, eventObj) => async (dispatch) => {
  try {
    dispatch(updateAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.put(eventId, eventObj)

    dispatch(updateSuccess(response))
    return true
  } catch (error) {
    dispatch(updateFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const deleteEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.delete(eventId)

    dispatch(deleteSuccess(response))
    return true
  } catch (error) {
    dispatch(deleteFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const enable = (eventId) => async (dispatch) => {
  try {
    dispatch(enableAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.enableEvent(eventId)

    dispatch(enableSuccess(response))
    return true
  } catch (error) {
    dispatch(enableFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const disable = (eventId) => async (dispatch) => {
  try {
    dispatch(disableAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.disableEvent(eventId)

    dispatch(disableSuccess(response))
    return true
  } catch (error) {
    dispatch(disableFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const sendNotification = (eventId) => async (dispatch) => {
  try {
    dispatch(sendNotificationAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.sendNotify(eventId)

    dispatch(sendNotificationSuccess(response))
  } catch (error) {
    dispatch(sendNotificationFailure(error))
    throw error
  }
}

export const loadImages = (eventId) => async (dispatch) => {
  try {
    dispatch(loadImagesAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getImages(eventId)

    dispatch(loadImagesSuccess(response))
    return true
  } catch (error) {
    dispatch(loadImagesFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const setDefaultImage = (eventId, imageId) => async (dispatch) => {
  try {
    dispatch(setDefaultImageAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.setDefaultImage(eventId, imageId)

    dispatch(setDefaultImageSuccess(response))
    return true
  } catch (error) {
    dispatch(setDefaultImageFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const addImage = (eventId, formData) => async (dispatch) => {
  try {
    dispatch(addImageAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.addImage(eventId, formData)

    dispatch(addImageSuccess(response))
    return true
  } catch (error) {
    dispatch(addImageFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const deleteImage = (eventId, imageId) => async (dispatch) => {
  try {
    dispatch(deleteImageAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.deleteImage(eventId, imageId)

    dispatch(deleteImageSuccess(response))
    return true
  } catch (error) {
    dispatch(deleteImageFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

//tickets
export const loadTicketPage = (eventId) => async (dispatch) => {
  try {
    dispatch(pagedListTicketAttempt())

    const proxy = new EventsProxy()
    const all = await proxy.getTickets(eventId)
    dispatch(pagedListTicketSuccess(all))
    return all
  } catch (error) {
    dispatch(pagedListTicketFailure(error))
    throw error
  }
}

export const loadTicketsOfEvent = (eventId) => async (dispatch) => {
  try {
    dispatch(loadRuleTicketsAttempt())

    const proxy = new EventsProxy()
    const all = await proxy.getTickets(eventId)

    dispatch(loadRuleTicketsSuccess(all))

    return all
  } catch (error) {
    dispatch(loadRuleTicketsError(error))
    throw error
  }
}

export const loadTicket = (eventId, ticketId) => async (dispatch) => {
  try {
    dispatch(loadTicketAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getTicketById(eventId, ticketId)

    dispatch(loadTicketSuccess(response))
    return response
  } catch (error) {
    dispatch(loadTicketFailure(error))
    throw error
  }
}

export const loadTicketWithCombo = (eventId, ticketId) => async (dispatch) => {
  try {
    dispatch(loadTicketWithComboAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getTicketByIdWithCombo(eventId, ticketId)

    dispatch(loadTicketWithComboSuccess(response))
  } catch (error) {
    dispatch(loadTicketWithComboFailure(error))
    throw error
  }
}

export const createTicket = (eventId, ticket) => async (dispatch) => {
  try {
    dispatch(createTicketAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.postTicket(eventId, ticket)

    dispatch(createTicketSuccess(response))
    return response
  } catch (error) {
    dispatch(createTicketFailure(error))
    throw error
  }
}

export const createTicketCash = (eventId, ticket) => async (dispatch) => {
  try {
    dispatch(createTicketAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.postTicketCash(eventId, ticket)

    dispatch(createTicketSuccess(response))
    return response
  } catch (error) {
    dispatch(createTicketFailure(error))
    throw error
  }
}

export const updateTicket = (eventId, ticketId, ticket) => async (dispatch) => {
  try {
    dispatch(updateTicketAttempt())
    const proxy = new EventsProxy()
    const response = await proxy.putTicket(eventId, ticketId, ticket)

    dispatch(updateTicketSuccess(response))
    return response
  } catch (error) {
    dispatch(updateTicketFailure(error))
    throw error
  }
}

export const updateTicketCash =
  (eventId, ticketId, ticket) => async (dispatch) => {
    try {
      dispatch(updateTicketAttempt())
      const proxy = new EventsProxy()
      const response = await proxy.putTicketCash(eventId, ticketId, ticket)

      dispatch(updateTicketSuccess(response))
      return response
    } catch (error) {
      dispatch(updateTicketFailure(error))
      throw error
    }
  }

export const deleteTicket = (eventId, ticketId) => async (dispatch) => {
  try {
    dispatch(deleteTicketAttempt())

    const proxy = new EventsProxy()
    const ticket = await proxy.deleteTicket(eventId, ticketId)

    dispatch(deleteTicketSuccess(ticket))
  } catch (error) {
    dispatch(deleteFailure(error))
    throw error
  }
}

export const loadTicketByUsage = (eventId, ticketUsage) => async (dispatch) => {
  try {
    dispatch(pagedListTicketAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getTicketByUsage(eventId, ticketUsage)

    dispatch(pagedListTicketSuccess(response))
    return true
  } catch (error) {
    dispatch(pagedListTicketFailure(error))
    dispatch(showSnackbarError(error))
    return false
  }
}

export const getTicketTypesReport = (eventId) => async (dispatch) => {
  try {
    dispatch(getTicketTypesReportAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.getTicketsReport(eventId)

    dispatch(getTicketTypesReportSuccess(response))
  } catch (error) {
    dispatch(getTicketTypesReportFailure(error))
    throw error
  }
}

export const shareUrl = (eventId) => async (dispatch) => {
  try {
    dispatch(shareUrlAttempt())

    const proxy = new EventsProxy()
    const response = await proxy.shareUrl(eventId)

    dispatch(shareUrlSuccess(response))
    return response
  } catch (error) {
    dispatch(shareUrlFailure(error))
    throw error
  }
}

export const setTicketType = (ticketType) => (dispatch) => {
  dispatch(ticketTypeSet(ticketType))
}
