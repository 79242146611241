import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  fieldRow: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  fieldSelect: {
    marginBottom: theme.spacing(2),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: 300,
      minWidth: 350
    }
  },
  containerDialog: {
    display: 'flex',
    flexDirection: 'column',
    paddingInline: 16,
    paddingBottom: 12
  },
  dialogButton: {
    width: 120,
    alignSelf: 'end'
  },
  textButton: {
    textTransform: 'none',
    height: 55,
    marginTop: 10,
    marginInline: theme.spacing(1),
    marginBottom: 5,
    paddingInline: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  containerSelectButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  field: {
    margin: theme.spacing(),
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      whiteSpace: 'normal'
    }
  },
  fieldEditorText: {
    margin: theme.spacing(),
    width: '100%',
    borderRadius: 5,
    fontFamily: 'sans-serif',
    minHeight: 160,
    lineHeight: 1.2
  },
  fieldEditorTextError: {
    border: '1px solid red'
  },
  containerCheckbox: {
    marginLeft: theme.spacing(1)
  },
  menuItem: {
    overflow: 'auto',
    whiteSpace: 'normal !important',
    minWidth: '100%',
    maxWidth: 'fit-content'
  },
  buttons: {
    margin: theme.spacing(1)
  },
  inputLabel: {
    marginLeft: theme.spacing(2)
  },
  inputLabelError: {
    color: 'red'
  },
  containerProgress: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  circularProgress: {
    marginRight: '.7rem'
  },
  actionsContainer: {
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey[400],
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    '& button': {
      marginRight: theme.spacing()
    }
  }
}))

export default useStyles
