import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  TextField,
  InputLabel
} from '@material-ui/core'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Save, ArrowDropDown } from '@material-ui/icons'
import { Formik } from 'formik'
import { TICKET_USAGE } from 'config/constants'
import { isNotIntegerNumber } from 'views/utils/functions'
import * as Yup from 'yup'
import moment from 'moment'
import clsx from 'clsx'
import { useStyles } from '../EventTicketForm.style'
import { TicketRules } from 'views/pages/EventsPage/components/TicketRules'
import { TicketsComboDialog } from 'views/pages/EventsPage/components/TicketsCombo'
import { useDispatch, useSelector } from 'react-redux'
import { loadEvents, loadTicketsOfEvent } from 'state/modules/events'

const isValidArray = (ids) => {
  let isValid = true
  const idsArray = ids?.split(',')
  const arrayFiltered = idsArray?.filter((v, i) => idsArray?.indexOf(v) === i)
  if (arrayFiltered?.length < idsArray?.length) {
    isValid = false
  }
  return isValid
}

const validationSchema = (salesEndDateTime) =>
  Yup.object().shape({
    Name: Yup.string()
      .required('Es requerido ingresar nombre del ticket')
      .max(250, "La cantidad máxima de caracteres es de '250'"),
    Price: Yup.number()
      .required('Es requerido ingresar el Precio')
      .typeError('El Precio debe ser un número')
      .moreThan(0, 'El Precio debe ser mayor a 0'),
    Remaining: Yup.number()
      .typeError('La cantidad debe ser un número')
      .required('Es requerido ingresar cantidad de tickets')
      .moreThan(0, 'La cantidad debe ser mayor a 0'),
    ComboTickets: Yup.string()
      .matches('^[0-9]+(?:,[0-9]+)*$', 'Formato incorrecto')
      .test('combo-tickets', 'No se permiten Ids repetidos', (ids) =>
        isValidArray(ids)
      ),
    Rules: Yup.string(),
    MaxQuantityToSale: Yup.number()
      .nullable(true)
      .integer('Solo se permiten valores enteros')
      .typeError('Solo se permiten valores numéricos')
      .min(0, 'La cantidad no puede ser menor a 0'),
    TransferStartDateTime: Yup.date()
      .nullable()
      .when('EnabledTransferStartDate', {
        is: true,
        then: Yup.date().test(
          'maxDate',
          'La fecha y hora de inicio de transferencias debe ser anterior a la fecha y hora de corte de venta',
          function (value) {
            return moment(value).isBefore(moment(salesEndDateTime))
          }
        )
      })
  })

export const TicketServiceChargeForm = ({
  values,
  isCloned,
  getComboTickets,
  getIdsTickets,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    checkedC: !values.DisabledDatesForm,
    checkedD: false
  })

  const [disableFormA, setDisableFormA] = useState(values.DisabledDatesForm)
  const [openDialogTickets, setOpenDialogTickets] = useState(false)

  const toggleA = (values, setFieldValue) => {
    setDisableFormA((d) => !d)
    setFieldValue('DisabledDatesForm', !values.DisabledDatesForm)
  }
  const [hideRules, setHideRules] = useState(true)

  const toggleRules = () => setHideRules((d) => !d)

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  // Being rules section
  const {
    events,
    event,
    ruleTickets: tickets,
    actionPending: pendingEvents,
    ruleTicketsPending: pendingTickets
  } = useSelector((state) => state.events)

  const SalesEndDateTime = event?.SalesEndDateTime

  const toggleCheckDate = (values, setFieldValue) => {
    const newDisabled = !values.EnabledTransferStartDate
    setFieldValue('EnabledTransferStartDate', newDisabled)
  }

  const fetchEvents = useCallback(async () => {
    await dispatch(loadEvents())
  }, [dispatch])

  const fetchTicketsOfEvent = useCallback(
    async (eventId) => {
      return await dispatch(loadTicketsOfEvent(eventId))
    },
    [dispatch]
  )

  useEffect(() => {
    values.Commissions = []
    if (actionText === 'Crear' && !isCloned) {
      values.Price = ''
    }
    if (!hideRules) {
      fetchEvents().then()
    }
  }, [fetchEvents, hideRules, values, actionText, isCloned])
  // End rules section

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema(SalesEndDateTime)}
      onSubmit={(values) => {
        if (hideRules) {
          values.Rules = ''
        }
        handleSubmit(values)
      }}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={actionSuccess}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  values.DisabledDatesForm = state.checkedC
                  values.TicketUsage = TICKET_USAGE.SERVICE_CHARGE_ONLY
                  handleSubmit(values)
                }}
                disabled={actionPending || actionSuccess}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Name && touched.Name && errors.Name) ||
                  'Nombre del ticket (visible al usuario)'
                }
                name='Name'
                value={values.Name}
                onChange={handleChange}
                onFocus={(v) => {
                  handleBlur(v)
                }}
                error={touched.Name && Boolean(errors.Name)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
                autoFocus
              />
            </div>

            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Price && touched.Price && errors.Price) ||
                  'Precio al público (Cargo por Servicio)'
                }
                name='Price'
                type='string'
                value={values.Price}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ maxLength: 9 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={touched.Price && Boolean(errors.Price)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Remaining && touched.Remaining && errors.Remaining) ||
                  'Cantidad'
                }
                name='Remaining'
                type='string'
                value={values.Remaining}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{ maxLength: 9 }}
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                error={touched.Remaining && Boolean(errors.Remaining)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.MaxQuantityToSale &&
                    touched.MaxQuantityToSale &&
                    errors.MaxQuantityToSale) ||
                  'Cantidad máxima de Tickets para vender'
                }
                name='MaxQuantityToSale'
                type='number'
                value={values.MaxQuantityToSale}
                inputProps={{ maxLength: 9 }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.MaxQuantityToSale && Boolean(errors.MaxQuantityToSale)
                }
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <FormControl
              variant='outlined'
              className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
              <InputLabel shrink={true}>
                {(errors.ComboTickets &&
                  touched.ComboTickets &&
                  errors.ComboTickets) ||
                  'Tickets Ids de Combos'}
              </InputLabel>
              <Button
                disableFocusRipple
                onClick={() => setOpenDialogTickets(true)}
                variant='outlined'
                className={classes.textButton}
                endIcon={<ArrowDropDown />}>
                {values.ComboTickets
                  ? values.ComboTickets
                  : 'Seleccione tickets'}
              </Button>
              <TicketsComboDialog
                comboTickets={getComboTickets(values?.ComboTicketModel)}
                idsTickets={getIdsTickets(values.ComboTickets)}
                openDialogTickets={openDialogTickets}
                setOpenDialogTickets={setOpenDialogTickets}
                setFieldValue={setFieldValue}
              />
            </FormControl>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.IsTransferable}
                    onChange={handleChange}
                    name='IsTransferable'
                  />
                }
                label='Es transferible?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.Active}
                    onChange={handleChange}
                    name='Active'
                  />
                }
                label='Habilitado?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.ShowRemaining}
                    onChange={handleChange}
                    name='ShowRemaining'
                  />
                }
                label='Mostrar cantidad?'
              />
            </div>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checkedC}
                    onChange={handleChangeCheck}
                    onClick={() => {
                      toggleA(values, setFieldValue)
                    }}
                    name='checkedC'
                  />
                }
                label='Habilitar fecha de inicio y de fin de venta de ticket?'
              />
            </div>
            <span>
              {!disableFormA && (
                <div className={classes.fieldsContainer}>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleStartDate'
                      format='DD/MM/YYYY'
                      name='SaleStartDate'
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Fecha de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      onBlur={handleBlur}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleStartDate'
                      className={classes.field}
                      value={values.SaleStartDate}
                      label={
                        (errors.SaleStartDate &&
                          touched.SaleStartDate &&
                          errors.SaleStartDate) ||
                        'Hora de Inicio'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleStartDate', value)
                      }}
                      error={
                        touched.SaleStartDate && Boolean(errors.SaleStartDate)
                      }
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                  <FormControl variant='outlined' className={classes.fieldRow}>
                    <DatePicker
                      locale='es'
                      id='SaleEndDate'
                      format='DD/MM/YYYY'
                      name='SaleEndDate'
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Fecha de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      onBlur={handleBlur}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      disableToolbar
                      className={classes.field}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                      animateYearScrolling
                    />
                    <TimePicker
                      locale='es'
                      name='SaleEndDate'
                      className={classes.field}
                      value={values.SaleEndDate}
                      label={
                        (errors.SaleEndDate &&
                          touched.SaleEndDate &&
                          errors.SaleEndDate) ||
                        'Hora de Fin'
                      }
                      onChange={(value) => {
                        setFieldValue('SaleEndDate', value)
                      }}
                      error={touched.SaleEndDate && Boolean(errors.SaleEndDate)}
                      fullWidth
                      size='small'
                      variant='inline'
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </div>
              )}
            </span>
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.EnabledTransferStartDate}
                    //onChange={handleChangeCheck}
                    onClick={() => {
                      toggleCheckDate(values, setFieldValue)
                    }}
                    name='checkedC'
                  />
                }
                label='¿Configurar fecha de inicio de transferencia por defecto?'
              />
            </div>
            {values.EnabledTransferStartDate && (
              <FormControl
                variant='outlined'
                className={clsx({
                  [classes.fieldRow]: true,
                  [classes.fieldErrorDate]: errors.TransferStartDateTime
                })}>
                <DatePicker
                  locale='es'
                  id='TransferStartDateTime'
                  format='DD/MM/YYYY'
                  name='TransferStartDateTime'
                  value={values.TransferStartDateTime}
                  label={
                    (errors.TransferStartDateTime &&
                      touched.TransferStartDateTime &&
                      errors.TransferStartDateTime) ||
                    'Fecha inicio de transferencias'
                  }
                  onChange={(value) => {
                    setFieldValue('TransferStartDateTime', value)
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.TransferStartDateTime &&
                    Boolean(errors.TransferStartDateTime)
                  }
                  disableToolbar
                  className={classes.field}
                  fullWidth
                  size='small'
                  variant='inline'
                  InputLabelProps={{
                    shrink: true
                  }}
                  animateYearScrolling
                />
                <TimePicker
                  locale='es'
                  name='TransferStartDateTime'
                  className={classes.field}
                  value={values.TransferStartDateTime}
                  ampm={false}
                  label={
                    (errors.TransferStartDateTime &&
                      touched.TransferStartDateTime &&
                      errors.TransferStartDateTime) ||
                    'Hora inicio de transferencias'
                  }
                  onChange={(value) => {
                    setFieldValue('TransferStartDateTime', value)
                  }}
                  error={
                    touched.TransferStartDateTime &&
                    Boolean(errors.TransferStartDateTime)
                  }
                  fullWidth
                  size='small'
                  variant='inline'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FormControl>
            )}
            <div className={classes.fieldRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'hasRules'}
                    checked={!hideRules}
                    onClick={toggleRules}
                  />
                }
                label='Reglas?'
              />
            </div>
            <span>
              {!hideRules && (
                <div className={classes.fieldsContainer}>
                  <TicketRules
                    value={values.Rules}
                    events={events}
                    tickets={tickets}
                    pendingEvents={pendingEvents}
                    pendingTickets={pendingTickets}
                    onRequestTicketsOfEvent={(event) =>
                      fetchTicketsOfEvent(event.Id)
                    }
                    onChange={(rules) => {
                      setFieldValue('Rules', rules)
                    }}
                  />
                </div>
              )}
            </span>
          </div>
          <div className={classes.actionsContainer}>
            <Button onClick={onCancel} disabled={actionSuccess} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                values.DisabledDatesForm = state.checkedC
                values.TicketUsage = TICKET_USAGE.SERVICE_CHARGE_ONLY
                handleSubmit(values)
              }}
              disabled={actionPending || actionSuccess}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default TicketServiceChargeForm
