import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  Collapse
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import { ArrowDropDown } from '@material-ui/icons'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { FilterComponent } from '~/views/shared'
import { useStyles } from './EventForm.style'
import { Save } from '@material-ui/icons'
import { EVENT_RESELLER_TYPE } from 'config/constants'
import moment from 'moment'
import {
  isNotIntegerNumber,
  getDefaultTransferStartDateTime
} from 'views/utils/functions'

const validationSchema = (isEventCreation) =>
  Yup.object().shape({
    Title: Yup.string()
      .required('Es requerido ingresar un nombre')
      .max(250, 'La cantidad máxima es de 250 caracteres'),
    Description: Yup.string().required('Es requerido ingresar una descripción'),
    LocationId: Yup.array()
      .min(1, 'Es requerido seleccionar un lugar')
      .required('Es requerido seleccionar un lugar'),
    OrganizerId: Yup.array()
      .min(1, 'Es requerido seleccionar un organizador')
      .required('Es requerido seleccionar un organizador'),
    TypeId: Yup.string().required('Es requerido seleccionar un tipo de evento'),
    StartDateTime: Yup.date().typeError('La Fecha de Inicio es requerida'),
    EndDateTime: Yup.date().typeError('La Fecha de Fin es requerida'),
    SalesEndDateTime: Yup.date().typeError('La Fecha de Corte es requerida'),
    DefaultTransferStartDateTime: Yup.date()
      .nullable()
      .when('EnabledTransferStartDate', {
        is: true,
        then: (schema) => {
          if (isEventCreation) {
            schema = schema.test(
              'minDate',
              'La fecha y hora de inicio de transferencias debe ser posterior a la fecha y hora actual',
              function (value) {
                return moment(value).isAfter(moment().subtract(3, 'hours'))
              }
            )
          }
          return schema.test(
            'maxDate',
            'La fecha y hora de inicio de transferencias debe ser anterior a la fecha y hora de corte de venta',
            function (value) {
              return moment(value).isBefore(
                moment(this.parent.SalesEndDateTime)
              )
            }
          )
        }
      }),
    EventResellerType: Yup.string().required(
      'Es requerido seleccionar un tipo de RRPP'
    ),
    MaxOnlineTicketSales: Yup.number()
      .required('Es requerido ingresar la cantidad máxima de tickets')
      .integer('Solo se permiten valores enteros')
      .typeError('Solo se permiten valores numéricos')
      .min(1, 'La cantidad no puede ser menor a 1')
      .max(6, 'La cantidad máxima es de 6')
  })

export const EventForm = ({
  values,
  locations,
  organizers,
  eventTypes,
  actionText,
  handleSubmit,
  onCancel,
  actionPending,
  actionSuccess
}) => {
  const classes = useStyles()
  const [showMercadoPagoChecks, setShowMercadoPagoChecks] = useState(false)
  //organizer
  const [openOrganizer, setOpenOrganizer] = useState(false)
  const [organizerSelected, setOrganizerSelected] = useState(
    values.OrganizerName
  )
  const [searchValueOrganizer, setSearchValueOrganizer] = useState('')
  const [organizersFilter, setOrganizersFilter] = useState(organizers)
  //location
  const [openLocation, setOpenLocation] = useState(false)
  const [locationSelected, setLocationSelected] = useState(values.LocationName)
  const [searchValueLocation, setSearchValueLocation] = useState('')
  const [locationsFilter, setLocationsFilter] = useState(locations)
  const [openError, setOpenError] = useState(false)

  const allowedAges = [
    { label: 'ATP', value: 0 },
    { label: '+13', value: 13 },
    { label: '+16', value: 16 },
    { label: '+18', value: 18 },
    { label: '+21', value: 21 }
  ]

  const toggleAccount = (e, checkAccount1, checkAccount2) => {
    if (!e.target.checked && !checkAccount1 && !checkAccount2) {
      setOpenError(true)
    }
    if (e.target.checked) {
      setOpenError(false)
    }
  }

  const handleClickOpenOrganizer = () => {
    setSearchValueOrganizer('')
    setOrganizersFilter(organizers)
    setOpenOrganizer(true)
  }

  const performSearchOrganizer = (searchKey) => {
    setSearchValueOrganizer(searchKey)
    setOrganizersFilter(
      organizers?.filter((o) =>
        o?.Name?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  //location
  const handleClickOpenLocation = () => {
    setSearchValueLocation('')
    setLocationsFilter(locations)
    setOpenLocation(true)
  }

  const performSearchLocation = (searchKey) => {
    setSearchValueLocation(searchKey)
    setLocationsFilter(
      locations?.filter((l) =>
        l?.Name?.toUpperCase().includes(searchKey.toUpperCase())
      )
    )
  }

  const handleOrganizerSelection = useCallback(
    (organizerId, setFieldValue = () => {}) => {
      if (!organizerId) {
        setShowMercadoPagoChecks(false)
      } else {
        const organizerIdNumber = Array.isArray(organizerId)
          ? organizerId[0]
          : parseInt(organizerId)
        const organizer = organizers.find((o) => o.Id === organizerIdNumber)
        setFieldValue('mpAuthorized', !!organizer?.MercadoPagoAuthorized)
        setShowMercadoPagoChecks(!!organizer?.MercadoPagoAuthorized)
      }
    },
    [organizers]
  )

  const toggleCheckDate = (values, setFieldValue) => {
    const newChecked = !values.EnabledTransferStartDate
    setFieldValue('EnabledTransferStartDate', newChecked)
    if (newChecked) {
      const defaultDate = getDefaultTransferStartDateTime(
        values.SalesEndDateTime
      )
      setFieldValue('DefaultTransferStartDateTime', defaultDate)
    }
  }

  useEffect(() => {
    if (values.OrganizerId != null) {
      handleOrganizerSelection(values.OrganizerId)
    }
  }, [handleOrganizerSelection, values])

  return (
    <Formik
      initialValues={values}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={validationSchema(actionText === 'Crear')}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldContainer}>
            <div className={classes.actionsContainerTop}>
              <Button
                onClick={onCancel}
                disabled={actionSuccess || isSubmitting}
                color='primary'>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if (!values.macroClick && !values.mercadoPago) {
                    setOpenError(true)
                  } else {
                    handleSubmit()
                  }
                }}
                disabled={actionPending || actionSuccess || isSubmitting}
                color='primary'
                variant='contained'
                startIcon={<Save />}>
                {actionPending || isSubmitting ? (
                  <CircularProgress size={24} color='secondary' />
                ) : (
                  actionText
                )}
              </Button>
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Title && touched.Title && errors.Title) ||
                  'Nombre del Evento'
                }
                name='Title'
                value={values.Title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Title && Boolean(errors.Title)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>
          <div className={classes.fieldContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Description &&
                    touched.Description &&
                    errors.Description) ||
                  'Información Adicional del Evento'
                }
                name='Description'
                required
                value={values.Description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.Description && Boolean(errors.Description)}
                fullWidth
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>

          <FormControl
            variant='outlined'
            className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
            <InputLabel shrink={true} required>
              {(errors.LocationId && touched.LocationId && errors.LocationId) ||
                'Lugar'}
            </InputLabel>
            <Button
              disableFocusRipple
              onClick={handleClickOpenLocation}
              variant='outlined'
              className={classes.textButton}
              endIcon={<ArrowDropDown />}>
              {locationSelected ? locationSelected : 'Seleccione un Lugar'}
            </Button>
            <Dialog open={openLocation} onClose={() => setOpenLocation(false)}>
              <div>
                <FilterComponent
                  performSearch={performSearchLocation}
                  searchValue={searchValueLocation}
                  placeholder='Ingrese Nombre...'
                />
              </div>
              <Select
                multiple
                native
                labelId='location-label'
                name='LocationId'
                id='LocationId-select'
                value={values.LocationId}
                onChange={handleChange}
                className={classes.fieldSelect}>
                {locationsFilter &&
                  locationsFilter.map((l) => (
                    <option
                      key={l.Id}
                      className={classes.items}
                      value={l.Id.toString()}
                      onClick={() => {
                        setLocationSelected(l.Name)
                        setOpenLocation(false)
                      }}>
                      {l.Name}
                    </option>
                  ))}
              </Select>
            </Dialog>
          </FormControl>
          <FormControl
            variant='outlined'
            className={`${classes.fieldRow} ${classes.fieldRowSelect}`}>
            <InputLabel shrink={true} required>
              {(errors.OrganizerId &&
                touched.OrganizerId &&
                errors.OrganizerId) ||
                'Organizador'}
            </InputLabel>
            <Button
              disableFocusRipple
              onClick={handleClickOpenOrganizer}
              variant='outlined'
              className={classes.textButton}
              endIcon={<ArrowDropDown />}>
              {organizerSelected
                ? organizerSelected
                : 'Seleccione un Organizador'}
            </Button>
            <Dialog
              open={openOrganizer}
              onClose={() => setOpenOrganizer(false)}>
              <div>
                <FilterComponent
                  performSearch={performSearchOrganizer}
                  searchValue={searchValueOrganizer}
                  placeholder='Ingrese Nombre...'
                />
              </div>
              <Select
                multiple
                native
                labelId='organizer-label'
                name='OrganizerId'
                id='organizerId-select'
                value={values.OrganizerId}
                onChange={(v) => {
                  handleChange(v)
                  handleOrganizerSelection(v.target.value, setFieldValue)
                  setFieldValue()
                }}
                className={classes.fieldSelect}>
                {organizersFilter &&
                  organizersFilter.map((o) => (
                    <option
                      key={o.Id}
                      className={classes.items}
                      value={o.Id.toString()}
                      onClick={() => {
                        setOrganizerSelected(o.Name)
                        setOpenOrganizer(false)
                      }}>
                      {o.Name}
                    </option>
                  ))}
              </Select>
            </Dialog>
          </FormControl>
          <FormControl variant='outlined' className={classes.fieldRow}>
            <InputLabel
              required
              error={
                touched.EventResellerType && Boolean(errors.EventResellerType)
              }>
              {(errors.EventResellerType &&
                touched.EventResellerType &&
                errors.EventResellerType) ||
                'RRPP habilitados para Venta Online'}
            </InputLabel>
            <Select
              labelId='EventResellerType-label'
              name='EventResellerType'
              id='EventResellerType-select'
              value={values.EventResellerType}
              onChange={handleChange}
              className={classes.field}
              fullWidth
              required
              error={
                touched.EventResellerType && Boolean(errors.EventResellerType)
              }
              size='small'
              label='RRPP habilitados para Venta Online'>
              <MenuItem value={EVENT_RESELLER_TYPE.BY_ORGANIZER}>
                RRPPs de la Productora
              </MenuItem>
              <MenuItem value={EVENT_RESELLER_TYPE.BY_TICKET_PASS}>
                RRPPs de TicketPass
              </MenuItem>
              <MenuItem value={EVENT_RESELLER_TYPE.ALL}>
                Todos los RRPPs
              </MenuItem>
              <MenuItem value={EVENT_RESELLER_TYPE.NONE}>Sin RRPPs</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant='outlined' className={classes.fieldRow}>
            <InputLabel required>
              {(errors.TypeId && touched.TypeId && errors.TypeId) ||
                'Tipo de Evento'}
            </InputLabel>
            <Select
              labelId='type-label'
              name='TypeId'
              id='typeId-select'
              value={values.TypeId}
              onChange={handleChange}
              className={classes.field}
              fullWidth
              required
              size='small'
              label='TypeId'>
              {eventTypes &&
                eventTypes.map((l) => (
                  <MenuItem key={l.Id} value={l.Id}>
                    {l.Name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <div className={classes.fieldContainer}>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.MaxOnlineTicketSales &&
                    touched.MaxOnlineTicketSales &&
                    errors.MaxOnlineTicketSales) ||
                  'Máximo de Tickets (Sólo cargo por Servicio y Venta Online) por Usuario'
                }
                name='MaxOnlineTicketSales'
                type='string'
                onKeyPress={(e) => isNotIntegerNumber(e)}
                onKeyDown={(e) => isNotIntegerNumber(e)}
                onPaste={(e) => e.preventDefault()}
                value={values.MaxOnlineTicketSales}
                inputProps={{ maxLength: 1 }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.MaxOnlineTicketSales &&
                  Boolean(errors.MaxOnlineTicketSales)
                }
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
          </div>

          <FormControl variant='outlined' className={classes.fieldRow}>
            <DatePicker
              locale='es'
              id='StartDateTime'
              format='DD/MM/YYYY'
              name='StartDateTime'
              value={values.StartDateTime}
              label={
                (errors.StartDateTime &&
                  touched.StartDateTime &&
                  errors.StartDateTime) ||
                'Fecha de Inicio'
              }
              onChange={(value) => {
                setFieldValue('StartDateTime', value.format())
                setFieldValue(
                  'EndDateTime',
                  moment(values.EndDateTime)
                    .set('year', value.year())
                    .set('month', value.month())
                    .set('date', value.date())
                    .format()
                )
                setFieldValue(
                  'SalesEndDateTime',
                  moment(values.SalesEndDateTime)
                    .set('year', value.year())
                    .set('month', value.month())
                    .set('date', value.date())
                    .format()
                )
              }}
              onBlur={handleBlur}
              error={touched.StartDateTime && Boolean(errors.StartDateTime)}
              disableToolbar
              className={classes.field}
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
              animateYearScrolling
            />
            <TimePicker
              locale='es'
              name='StartDateTime'
              className={classes.field}
              value={values.StartDateTime}
              ampm={false}
              label={
                (errors.StartDateTime &&
                  touched.StartDateTime &&
                  errors.StartDateTime) ||
                'Hora de Inicio (OpenDoors)'
              }
              onChange={(value) => {
                setFieldValue('StartDateTime', value.format())
              }}
              error={touched.StartDateTime && Boolean(errors.StartDateTime)}
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <FormControl variant='outlined' className={classes.fieldRow}>
            <DatePicker
              locale='es'
              id='EndDateTime'
              format='DD/MM/YYYY'
              name='EndDateTime'
              value={values.EndDateTime}
              label={
                (errors.EndDateTime &&
                  touched.EndDateTime &&
                  errors.EndDateTime) ||
                'Fecha de Fin'
              }
              onChange={(value) => {
                setFieldValue('EndDateTime', value)
              }}
              onBlur={handleBlur}
              error={touched.EndDateTime && Boolean(errors.EndDateTime)}
              disableToolbar
              className={classes.field}
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
              animateYearScrolling
            />
            <TimePicker
              locale='es'
              name='EndDateTime'
              className={classes.field}
              value={values.EndDateTime}
              ampm={false}
              label={
                (errors.EndDateTime &&
                  touched.EndDateTime &&
                  errors.EndDateTime) ||
                'Hora de Fin (en esta hora desaparece el evento de la lista de eventos disponibles)'
              }
              onChange={(value) => {
                setFieldValue('EndDateTime', value)
              }}
              error={touched.EndDateTime && Boolean(errors.EndDateTime)}
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <FormControl variant='outlined' className={classes.fieldRow}>
            <DatePicker
              locale='es'
              id='SalesEndDateTime'
              format='DD/MM/YYYY'
              name='SalesEndDateTime'
              value={values.SalesEndDateTime}
              label={
                (errors.SalesEndDateTime &&
                  touched.SalesEndDateTime &&
                  errors.SalesEndDateTime) ||
                'Fecha de corte de venta online y transferencias'
              }
              onChange={(value) => {
                setFieldValue('SalesEndDateTime', value)
              }}
              onBlur={handleBlur}
              error={
                touched.SalesEndDateTime && Boolean(errors.SalesEndDateTime)
              }
              disableToolbar
              className={classes.field}
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
              animateYearScrolling
            />
            <TimePicker
              locale='es'
              name='SalesEndDateTime'
              className={classes.field}
              value={values.SalesEndDateTime}
              ampm={false}
              label={
                (errors.SalesEndDateTime &&
                  touched.SalesEndDateTime &&
                  errors.SalesEndDateTime) ||
                'Hora de corte de venta online y transferencias'
              }
              onChange={(value) => {
                setFieldValue('SalesEndDateTime', value)
              }}
              error={
                touched.SalesEndDateTime && Boolean(errors.SalesEndDateTime)
              }
              fullWidth
              required
              size='small'
              variant='inline'
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.EnabledTransferStartDate}
                  //onChange={handleChangeCheck}
                  onClick={() => {
                    toggleCheckDate(values, setFieldValue)
                  }}
                  name='checkedTransferDate'
                />
              }
              label='¿Configurar fecha de inicio de transferencia por defecto?'
            />
          </div>
          {values.EnabledTransferStartDate && (
            <FormControl
              variant='outlined'
              className={clsx({
                [classes.fieldRow]: true,
                [classes.fieldErrorDate]: errors.TransferStartDateTime
              })}>
              <DatePicker
                locale='es'
                id='DefaultTransferStartDateTime'
                format='DD/MM/YYYY'
                name='DefaultTransferStartDateTime'
                value={values.DefaultTransferStartDateTime}
                label={
                  (errors.DefaultTransferStartDateTime &&
                    touched.DefaultTransferStartDateTime &&
                    errors.DefaultTransferStartDateTime) ||
                  'Fecha inicio de transferencias'
                }
                onChange={(value) => {
                  setFieldValue('DefaultTransferStartDateTime', value)
                }}
                onBlur={handleBlur}
                error={
                  touched.DefaultTransferStartDateTime &&
                  Boolean(errors.DefaultTransferStartDateTime)
                }
                disableToolbar
                className={classes.field}
                fullWidth
                size='small'
                variant='inline'
                InputLabelProps={{
                  shrink: true
                }}
                animateYearScrolling
              />
              <TimePicker
                locale='es'
                name='DefaultTransferStartDateTime'
                className={classes.field}
                value={values.DefaultTransferStartDateTime}
                ampm={false}
                label={
                  (errors.DefaultTransferStartDateTime &&
                    touched.DefaultTransferStartDateTime &&
                    errors.DefaultTransferStartDateTime) ||
                  'Hora inicio de transferencias'
                }
                onChange={(value) => {
                  setFieldValue('DefaultTransferStartDateTime', value)
                }}
                error={
                  touched.DefaultTransferStartDateTime &&
                  Boolean(errors.DefaultTransferStartDateTime)
                }
                fullWidth
                size='small'
                variant='inline'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl>
          )}
          <FormControl variant='standard' className={classes.admissionAge}>
            <InputLabel>Edad de admisión al evento</InputLabel>
            <Select
              name='AdmissionAge'
              labelId='AdmissionAge-label'
              id='AdmissionAge-select'
              value={values.AdmissionAge}
              onChange={handleChange}
              fullWidth
              size='small'
              label='AdmissionAge'>
              {allowedAges &&
                allowedAges.map((age) => (
                  <MenuItem key={age.label} value={age.value}>
                    {age.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsVisibleForOrganizer}
                  onChange={handleChange}
                  name='IsVisibleForOrganizer'
                />
              }
              label='¿Es visible para el organizador?'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsHidden}
                  onChange={handleChange}
                  name='IsHidden'
                />
              }
              label='¿Está oculto?'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.IsSoldOut}
                  onChange={handleChange}
                  name='IsSoldOut'
                />
              }
              label='¿Está agotado?'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.Billable}
                  onChange={handleChange}
                  name='Billable'
                />
              }
              label='¿Es Facturable?'
            />
          </div>
          {showMercadoPagoChecks && (
            <>
              <div className={classes.fieldRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.PayDirectToOrganizer}
                      onChange={handleChange}
                      name='PayDirectToOrganizer'
                    />
                  }
                  label='Rendir directo al organizador'
                />
              </div>
              <div className={classes.fieldRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.IncludeInMercadoPagoAccounting}
                      onChange={handleChange}
                      name='IncludeInMercadoPagoAccounting'
                    />
                  }
                  label='Incluir en contabilidad de MP'
                />
              </div>
            </>
          )}
          <Typography>Modalidades de pagos aceptadas</Typography>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.creditCard}
                  onChange={handleChange}
                  name='creditCard'
                />
              }
              label='Tarjetas de Crédito'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.debitCard}
                  onChange={handleChange}
                  name='debitCard'
                />
              }
              label='Tarjetas de Débito'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.ticket}
                  onChange={handleChange}
                  name='ticket'
                />
              }
              label='RapiPago, PagoFacil, etc'
            />
          </div>
          <Typography>
            Pasarelas de pago que el usuario podrá utilizar para pagar
          </Typography>
          <Collapse in={openError} timeout='auto' unmountOnExit>
            <Alert severity='error' className={classes.alertAccount}>
              Debe seleccionar al menos una pasarela de pago.
            </Alert>
          </Collapse>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.mercadoPago}
                  onChange={handleChange}
                  onClick={(e) =>
                    toggleAccount(e, values.macroClick, values.getAmplify)
                  }
                  name='mercadoPago'
                />
              }
              label='MercadoPago'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.macroClick}
                  onChange={handleChange}
                  onClick={(e) =>
                    toggleAccount(e, values.mercadoPago, values.getAmplify)
                  }
                  name='macroClick'
                />
              }
              label='MacroClick'
            />
          </div>
          <div className={classes.fieldRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.getAmplify}
                  onChange={handleChange}
                  onClick={(e) =>
                    toggleAccount(e, values.mercadoPago, values.macroClick)
                  }
                  name='getAmplify'
                />
              }
              label='GetAmplify'
            />
          </div>
          <div>
            <Alert
              severity='warning'
              variant='outlined'
              className={classes.warning}>
              Si usted no dispone de las credenciales de su cuenta Macro o
              MercadoPago, debe contactar y solicitar a soporte para que se
              agregue y se pueda cobrar con esa plataforma.
            </Alert>
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={onCancel}
              disabled={actionSuccess || isSubmitting}
              color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                if (
                  !values.macroClick &&
                  !values.mercadoPago &&
                  !values.getAmplify
                ) {
                  setOpenError(true)
                } else {
                  handleSubmit()
                }
              }}
              disabled={actionPending || actionSuccess || isSubmitting}
              color='primary'
              variant='contained'
              startIcon={<Save />}>
              {actionPending || isSubmitting ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default EventForm
