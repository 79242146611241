import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { EventForm } from '../components'
import { loadEventManager, update } from 'state/modules/events'
import {
  fetchEventTypes,
  fetchLocations,
  fetchOrganizers
} from 'state/modules/common'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'
import { PAYMENT_IDS, ACCOUNTS } from 'config/constants'
import * as moment from 'moment'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'

export const EventsUpdatePage = ({ onSuccess, match }) => {
  const dispatch = useDispatch()
  const { event, pending, actionPending, actionSuccess } = useSelector(
    (state) => state.events
  )
  const {
    locations,
    organizers,
    eventTypes,
    pending: pendingData
  } = useSelector((state) => state.common)

  useEffect(() => {
    async function fetchData() {
      await dispatch(fetchLocations())
      await dispatch(fetchOrganizers())
      await dispatch(fetchEventTypes())
      await dispatch(loadEventManager(match.params.eventId))
    }

    fetchData().then()
  }, [dispatch, match])

  const datesValidate = (startDate, endDate, salesEndDate) => {
    let validate = false
    if (moment(salesEndDate).diff(moment(startDate), 'minutes') > 0) {
      showSnackbarError(
        'La Fecha y Hora de corte no pueden ser superior a la Fecha y Hora de Inicio.',
        4000
      )
    } else {
      if (moment().subtract(3, 'hours').isAfter(startDate, 'day')) {
        showSnackbarError(
          'La Fecha de Inicio no puede ser anterior a la Fecha actual.'
        )
      } else {
        if (moment().subtract(3, 'hours').isAfter(startDate)) {
          showSnackbarError(
            'La Hora de Inicio no puede ser anterior a la Hora actual.'
          )
        } else {
          if (moment(startDate).isAfter(endDate, 'day')) {
            showSnackbarError(
              'La Fecha de Fin no puede ser anterior a la Fecha de Inicio.'
            )
          } else {
            if (moment(startDate).isAfter(endDate)) {
              showSnackbarError(
                'La Hora de Fin no puede ser anterior a la Hora de Inicio.'
              )
            } else {
              validate = true
            }
          }
        }
      }
    }
    return validate
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const locationId = values.LocationId
    const organizerId = values.OrganizerId
    try {
      if (
        datesValidate(
          values?.StartDateTime,
          values?.EndDateTime,
          values?.SalesEndDateTime
        )
      ) {
        setSubmitting(true)
        values.AllowedPaymentTypeId = 0
        values.creditCard &&
          (values.AllowedPaymentTypeId |= PAYMENT_IDS.CREDIT_CARD)
        values.debitCard &&
          (values.AllowedPaymentTypeId |= PAYMENT_IDS.DEBIT_CARD)
        values.ticket && (values.AllowedPaymentTypeId |= PAYMENT_IDS.TICKET)
        values.LocationId = parseInt(values.LocationId[0])
        values.OrganizerId = parseInt(values.OrganizerId[0])

        values.PaymentGatewayAvailable = ACCOUNTS.NONE
        if (values.mercadoPago) {
          values.PaymentGatewayAvailable |= ACCOUNTS.MERCADO_PAGO
        }
        if (values.macroClick) {
          values.PaymentGatewayAvailable |= ACCOUNTS.MACRO
        }
        if (values.getAmplify) {
          values.PaymentGatewayAvailable |= ACCOUNTS.GET_AMPLIFY
        }
        if (!values.EnabledTransferStartDate) {
          values.DefaultTransferStartDateTime = null
        }
        const success = await dispatch(update(values.Id, values))
        if (success) {
          onSuccess()
          showSnackbarSuccess('Evento Actualizado!')
        }
      }
    } catch (error) {
      console.log(error)
      showSnackbarError(error)
    } finally {
      setSubmitting(false)
    }
    values.LocationId = locationId
    values.OrganizerId = organizerId
  }

  const EnabledTransferStartDate = () => {
    return event?.DefaultTransferStartDateTime != null
  }

  const values = {
    Id: event?.Id,
    Title: event?.Title,
    Description: event?.Description,
    MaxOnlineTicketSales: event?.MaxOnlineTicketSales,
    LocationId: [event?.Location?.Id] || [],
    LocationName: event?.Location?.Name,
    OrganizerId: [event?.Organizer?.Id] || [],
    OrganizerName: event?.Organizer?.Name,
    TypeId: event?.Type?.Id || '',
    StartDateTime: event?.StartDateTime,
    EndDateTime: event?.EndDateTime,
    SalesEndDateTime: event?.SalesEndDateTime,
    DefaultTransferStartDateTime: event?.DefaultTransferStartDateTime,
    EnabledTransferStartDate: EnabledTransferStartDate(),
    IsVisibleForOrganizer: event?.IsVisibleForOrganizer,
    IsHidden: event?.IsHidden,
    IsSoldOut: event?.IsSoldOut,
    PayDirectToOrganizer: event?.PayDirectToOrganizer,
    IncludeInMercadoPagoAccounting: event?.IncludeInMercadoPagoAccounting,
    creditCard: !!(event?.AllowedPaymentTypeId & PAYMENT_IDS.CREDIT_CARD),
    debitCard: !!(event?.AllowedPaymentTypeId & PAYMENT_IDS.DEBIT_CARD),
    ticket: !!(event?.AllowedPaymentTypeId & PAYMENT_IDS.TICKET),
    EventResellerType: event?.EventResellerType,
    AdmissionAge: event?.AdmissionAge,
    Billable: event?.Billable,
    mercadoPago: !!(event?.PaymentGatewayAvailable & ACCOUNTS.MERCADO_PAGO),
    macroClick: !!(event?.PaymentGatewayAvailable & ACCOUNTS.MACRO),
    getAmplify: !!(event?.PaymentGatewayAvailable & ACCOUNTS.GET_AMPLIFY),
    PaymentGatewayAvailable:
      event?.PaymentGatewayAvailable || ACCOUNTS.MERCADO_PAGO
  }

  return (
    <PaperContainer title='Editar evento'>
      {pending || pendingData ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <EventForm
          values={values}
          locations={locations}
          organizers={organizers}
          eventTypes={eventTypes}
          actionText='Editar'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          actionSuccess={actionSuccess}
          onCancel={() => history.push(ROUTES.ADMIN.EVENTS.INDEX)}
        />
      )}
    </PaperContainer>
  )
}
