import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import {
  reset,
  sendNotificationToMaillistEvents
} from '~/state/modules/notifications'
import { PAYMENTS_STATUS } from 'config/constants'
import {
  PaperContainer,
  AppContainer,
  PreviewDialog,
  ConfirmationDialog
} from '~/views/shared'
import { NotificationsCustomByEventsForm } from './NotificationsCustomByEventsForm'
import { showSnackbarError } from 'views/utils/snackbar'

export const NotificationsCustomByEventsPage = () => {
  const { actionPending } = useSelector((state) => state.notifications)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  const [notification, setNotification] = useState()
  const [eventIds, setEventIds] = useState()
  const [eventsSelected, setEventsSelected] = useState([])
  const [paymentStatus, setPaymentStatus] = useState()
  const formikRef = useRef()

  const handleCloseConfirmation = () => {
    setOpenDialogConfirmation(false)
  }

  const handleClose = () => {
    dispatch(reset())
    setOpen(false)
  }

  const handlePreview = (values) => {
    const notification = {
      Subject: values.Subject,
      Message: values.Message
    }
    setNotification(notification)
    setOpenPreview(true)
  }

  const handleSubmit = (values) => {
    const notification = {
      Subject: values.Subject,
      Message: values.Message
    }
    setNotification(notification)
    setEventIds(values.EventIds)
    setEventsSelected(values.EventsSelected)
    setPaymentStatus(values.PaymentStatus)
    setOpenDialogConfirmation(true)
  }

  const handleClear = () => {
    formikRef.current.resetForm()
    setSuccessNotification(true)
  }

  const handleSubmitConfirmation = async () => {
    setOpenDialogConfirmation(false)
    const parameters = {
      EventIds: eventIds,
      PaymentStatus: paymentStatus
    }
    const payload = {
      Subject: notification.Subject,
      Message: notification.Message,
      Parameters: JSON.stringify(parameters)
    }
    try {
      await dispatch(sendNotificationToMaillistEvents(payload))
      handleClear()
      setOpen(true)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const paymentStatusArray = [
    { label: 'Aprobada', value: PAYMENTS_STATUS.APPROVED },
    { label: 'Pendiente', value: PAYMENTS_STATUS.PENDING },
    { label: 'Devuelta', value: PAYMENTS_STATUS.REFUNDED },
    { label: 'En Progreso', value: PAYMENTS_STATUS.IN_PROGRESS },
    { label: 'Cancelada', value: PAYMENTS_STATUS.CANCELLED },
    { label: 'Contracargo', value: PAYMENTS_STATUS.CHARGED_BACK },
    { label: 'En Mediación', value: PAYMENTS_STATUS.IN_MEDIATION },
    { label: 'Rechazada', value: PAYMENTS_STATUS.REJACTED }
  ]

  const values = {
    Subject: '',
    Message: '',
    EventIds: [],
    EventsSelected: null,
    PaymentStatus: '',
    showHtmlMessage: false,
    quantity: 20
  }

  return (
    <AppContainer>
      <PaperContainer title='Enviar email personalizado por eventos'>
        <NotificationsCustomByEventsForm
          values={values}
          paymentStatus={paymentStatusArray}
          formikRef={formikRef}
          actionText='Enviar'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          openPreview={handlePreview}
          successNotification={successNotification}
          handleChangeSuccess={() => setSuccessNotification(false)}
        />
      </PaperContainer>
      <Dialog open={open}>
        <DialogTitle>¡Email enviado!</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDialogConfirmation}
        loading={actionPending}
        handleConfirmation={handleSubmitConfirmation}
        onCancel={handleCloseConfirmation}
        title='ADVERTENCIA'
        message={
          'Está por enviar el mensaje a los usuarios de los siguientes eventos: '
        }
        entityIdentifier={
          <ul style={{ marginBlock: 0 }}>
            {eventsSelected.map((event) => (
              <li key={event}>{event}</li>
            ))}
          </ul>
        }
      />
      <PreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        subject={notification?.Subject}
        message={notification?.Message}
        userName='Estimado Cliente'
      />
    </AppContainer>
  )
}

export default NotificationsCustomByEventsPage
