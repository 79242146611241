import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'end',
    padding: 0,
    marginTop: -4,
    marginBottom: -12,
    marginRight: -4
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  buttonWithdraw: {
    width: '-webkit-fill-available',
    fontSize: 'smaller'
  }
}))

export const WithdrawalsFormPesosDialog = ({
  open,
  onClose,
  onCreate // callback para el botón "Crear"
}) => {
  const classes = useStyles()

  // Estados locales para simular la selección de opciones
  const [network, setNetwork] = useState('OP Sepolia')
  const [token, setToken] = useState('USDT')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          overflowX: 'hidden'
        }
      }}>
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.button}>
          <Close fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form className={classes.formContainer} style={{ width: 350 }}>
          <TextField
            label='Dirección de destino *'
            variant='outlined'
            fullWidth
            size='small'
          />

          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel>Red</InputLabel>
            <Select
              value={network}
              onChange={(e) => setNetwork(e.target.value)}
              label='Red'>
              <MenuItem value='OP Sepolia'>OP Sepolia</MenuItem>
              <MenuItem value='Otra Red'>Ethereum Mainnet</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant='outlined' size='small' fullWidth>
            <InputLabel>Moneda</InputLabel>
            <Select
              value={token}
              onChange={(e) => setToken(e.target.value)}
              label='Token'>
              <MenuItem value='USDT'>USDT</MenuItem>
              <MenuItem value='USDC'>USDC</MenuItem>
              <MenuItem value='ETH'>ARS</MenuItem>
            </Select>
          </FormControl>
          <div>
            <Button
              onClick={onCreate}
              variant='contained'
              color='primary'
              className={classes.buttonWithdraw}>
              Retirar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default WithdrawalsFormPesosDialog
