import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
  colors
} from '@material-ui/core'
import {
  ReportsWidget,
  TotalizatorWidget,
  StackedBarChartWidget,
  PieChartWidget
} from '../components'
import {
  loadEventOperations,
  loadInvitationsData,
  loadTotalizators,
  loadTotalTickets,
  getEventOperationsReport,
  resetDashboard,
  loadOperationsPercentages,
  loadTotalSalesPercentages,
  getInvitationsReport,
  getTicketTypesReport,
  getTicketCodesReport,
  loadCashSales,
  loadOnlineSales,
  loadServiceChargeSales,
  getRRPPSalesReport,
  getRRPPOnlineReport,
  getOrdersReport
} from 'state/modules/dashboard'
import { loadEvent } from 'state/modules/events'
import numeral from 'numeral'
import moment from 'moment'
import { ROLES } from 'config/constants'
import { useStyles } from './DashboardInfoPage.style'
import { EventTitle } from '../components/EventTitle'

export const DashboardInfoPage = ({ match }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { pending: pendingEvent, event } = useSelector((state) => state.events)
  const {
    pendingTotalizators,
    // pendingTotalTickets,
    pendingCashSales,
    pendingOnlineSales,
    pendingServiceChargeSales,
    pendingInvitations,
    pendingOperations,
    pendingTotalSalesPercentages,
    pendingRRPPSalesReport,
    totalizators,
    // totalTickets,
    invitationsData,
    operations,
    totalSalesPercentages,
    pendingTicketTypesReport,
    pendingInvitationsReport,
    pendingOperationsReport,
    pendingOperationsPercentages,
    pendingTicketCodesReport,
    pendingRRPPOnlineReport,
    pendingOrdersReport,
    operationsPercentages,
    cashSales,
    onlineSales,
    serviceChargeSales
  } = useSelector((state) => state.dashboard)
  const [operationDetails, setOperationDetails] = useState(null)
  const [createdGeneratedData, setCreatedGeneratedData] = useState(null)
  const [invitationTicketDetails, setInvitationTicketDetails] = useState(null)
  const { user } = useSelector((state) => state.login)
  const rolesArray = user?.roles?.split(',') || []
  const { eventId } = match.params

  const handleRequestOperationsReport = async () => {
    await dispatch(getEventOperationsReport(eventId))
  }

  const handleRequestInvitationsReport = async () => {
    await dispatch(getInvitationsReport(eventId))
  }

  const handleRequestTicketTypesReport = async () => {
    await dispatch(getTicketTypesReport(eventId))
  }

  const handleRRPPSalesReport = async () => {
    await dispatch(getRRPPSalesReport(eventId))
  }

  const handleRRPPOnlineReport = async () => {
    await dispatch(getRRPPOnlineReport(eventId))
  }

  const handleRequestOrdersReport = async (paymentStatus) => {
    await dispatch(getOrdersReport(eventId, paymentStatus))
  }

  const handleRequestTicketCodesReport = async () => {
    await dispatch(getTicketCodesReport(eventId))
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadEvent(eventId))
      await dispatch(loadTotalizators(eventId))
      await dispatch(loadEventOperations(eventId))
      await dispatch(loadTotalTickets(eventId))
      await dispatch(loadInvitationsData(eventId))
      await dispatch(loadCashSales(eventId))
      await dispatch(loadOnlineSales(eventId))
      await dispatch(loadServiceChargeSales(eventId))
      await dispatch(loadOperationsPercentages(eventId))
      await dispatch(loadTotalSalesPercentages(eventId))
    }

    fetchData().then()
    return () => {
      dispatch(resetDashboard())
    }
  }, [dispatch, eventId])

  useEffect(() => {
    if (operations != null) {
      const dataset = []
      const { Sales } = operations
      const { AllData: AllDataSales } = Sales
      const salesEntries = Object.keys(AllDataSales)

      salesEntries.forEach((e) => {
        const entry = AllDataSales[e]
        const { Details: details } = entry

        dataset.push({
          label: moment(e).format('DD / MM / YYYY'),
          values: details.map((d) => ({
            label: d.TicketTypeName,
            value: d.Units
          }))
        })
      })

      dataset.sort(
        (a, b) =>
          moment(a.label, 'DD / MM / YYYY').format('YYYYMMDD') -
          moment(b.label, 'DD / MM / YYYY').format('YYYYMMDD')
      )

      setOperationDetails(dataset)
    }
  }, [operations])

  useEffect(() => {
    if (invitationsData != null) {
      const { Totals, Details } = invitationsData
      let totalGenerated = 0
      let totalNotGenerated = 0

      Totals.forEach((t) => {
        totalGenerated += t.TotalGenerated
        totalNotGenerated += t.TotalNotGenerated
      })

      const createdGenerated = [
        {
          Label: 'Generadas',
          Value: totalGenerated
        },
        {
          Label: 'No Generadas',
          Value: totalNotGenerated
        }
      ]

      setCreatedGeneratedData(createdGenerated)

      const groups = []

      Details.forEach((d) => {
        const { CreatedByName, Invitations } = d
        groups.push({
          label: CreatedByName,
          values: Invitations.map((i) => ({
            label: i.Name,
            value: i.TotalCreated
          }))
        })
      })

      setInvitationTicketDetails(groups)
    }
  }, [invitationsData])

  return (
    <div className={classes.dashboardContainer}>
      {pendingEvent ? (
        <div className={classes.loadingCard}>
          <CircularProgress size={24} style={{ marginRight: '1rem' }} />
          <Typography variant={'caption'}>Cargando evento...</Typography>
        </div>
      ) : (
        <>
          <EventTitle event={event} />

          <Container maxWidth={false}>
            <Grid container spacing={2}>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  values={[
                    {
                      title: 'Total Entradas',
                      value: totalizators?.TotalTickets
                    },
                    {
                      title: 'Retiradas',
                      value: totalizators?.TotalWithdrawals
                    }
                  ]}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Total Vendidas'}
                  value={totalizators?.TotalSales}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  values={[
                    {
                      title: 'Contracargos',
                      value: totalizators?.TotalChargeBacks
                    },
                    {
                      title: 'Costos por Contracargos',
                      value: numeral(
                        totalizators?.TotalLossDueToChargeBacks
                      ).format('$0,0[.]00')
                    }
                  ]}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación Productora Online'}
                  value={numeral(totalizators?.TotalOnline).format('$0,0[.]00')}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación Productora Efectivo'}
                  value={numeral(totalizators?.TotalCash).format('$0,0[.]00')}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación Productora Total'}
                  value={numeral(totalizators?.TotalProfit).format('$0,0[.]00')}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación RRPP Online'}
                  value={numeral(
                    totalizators?.TotalResellerProfitOnline
                  ).format('$0,0[.]00')}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación RRPP Efectivo'}
                  value={numeral(totalizators?.TotalResellerProfitCash).format(
                    '$0,0[.]00'
                  )}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación RRPP Total'}
                  value={numeral(totalizators?.TotalResellerProfit).format(
                    '$0,0[.]00'
                  )}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación Productora (crypto)'}
                  value={numeral(totalizators?.TotalCryptoProducer).format(
                    '$0,0[.]00'
                  )}
                  loading={pendingTotalizators}
                />
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={12}>
                <TotalizatorWidget
                  title={'Facturación RRPP (crypto)'}
                  value={numeral(totalizators?.TotalCryptoReseller).format(
                    '$0,0[.]00'
                  )}
                  loading={pendingTotalizators}
                />
              </Grid>

              {rolesArray?.includes(ROLES.ADMIN) && (
                <>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      values={[
                        {
                          title: 'TicketPass Online (crypto)',
                          value: numeral(
                            totalizators?.TotalCryptoTicketPass
                          ).format('$0,0[.]00')
                        },
                        {
                          title: 'Comision de Pasarela de pago (crypto)',
                          value: numeral(
                            totalizators?.TotalCryptoPaymentGateway
                          ).format('$0,0[.]00')
                        }
                      ]}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title={'TicketPass Online'}
                      value={numeral(
                        totalizators?.TotalTicketPassProfitOnline
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title={'TicketPass Efectivo'}
                      value={numeral(
                        totalizators?.TotalTicketPassProfitCash
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title={'TicketPass Total'}
                      value={numeral(
                        totalizators?.TotalTicketPassProfit
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title='TicketPass costo por Contracargos'
                      value={numeral(
                        totalizators?.TotalTicketPassLossDueToChargeBacks
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title='TicketPass Descuento por Fidelización'
                      value={numeral(
                        totalizators?.TotalTicketPassDiscountDueToLoyaltyProgram
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <></>
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title={'Comisión de MercadoPago'}
                      value={numeral(
                        totalizators?.TotalPaymentGatewayCommission
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xl={4} xs={12}>
                    <TotalizatorWidget
                      title={'Total (MercadoPago + ticketPass)'}
                      value={numeral(
                        totalizators?.TotalPaymentGatewayCommission +
                          totalizators?.TotalTicketPassProfit
                      ).format('$0,0[.]00')}
                      loading={pendingTotalizators}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <StackedBarChartWidget
                  title={'Ventas por día'}
                  pending={pendingOperations}
                  dataset={operationDetails}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PieChartWidget
                  title={'Total Entradas'}
                  pending={pendingOperationsPercentages}
                  dataset={
                    operationsPercentages &&
                    operationsPercentages
                      .map((t) => ({
                        Label:
                          t.Operation === 'Sales'
                            ? 'Ventas'
                            : t.Operation === 'Invitations'
                              ? 'Invitaciones'
                              : t.Operation,
                        Value: t.Units
                      }))
                      .filter((i) => i.Value !== 0)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PieChartWidget
                  title={'Ventas'}
                  pending={pendingTotalSalesPercentages}
                  dataset={
                    totalSalesPercentages &&
                    totalSalesPercentages
                      .map((t) => ({ Label: t.TicketTypeName, Value: t.Unit }))
                      .filter((i) => i.Value !== 0)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PieChartWidget
                  title={`Ventas Online`}
                  pending={pendingOnlineSales}
                  dataset={
                    onlineSales &&
                    onlineSales
                      .map((t) => ({ Label: t.Operation, Value: t.Units }))
                      .filter((i) => i.Value !== 0)
                      .sort((a, b) => b.Value - a.Value)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PieChartWidget
                  title={`Ventas en Efectivo`}
                  pending={pendingCashSales}
                  dataset={
                    cashSales &&
                    cashSales
                      .map((t) => ({ Label: t.Operation, Value: t.Units }))
                      .filter((i) => i.Value !== 0)
                      .sort((a, b) => b.Value - a.Value)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PieChartWidget
                  title={`Ventas Solo Cargo por Servicio`}
                  pending={pendingServiceChargeSales}
                  dataset={
                    serviceChargeSales &&
                    serviceChargeSales
                      .map((t) => ({ Label: t.Operation, Value: t.Units }))
                      .filter((i) => i.Value !== 0)
                      .sort((a, b) => b.Value - a.Value)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={8}>
                <StackedBarChartWidget
                  title={'Detalle por Ticket de Invitación'}
                  dataset={invitationTicketDetails}
                  invitationsData={invitationsData}
                  eventId={match.params.eventId}
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={4}>
                <PieChartWidget
                  title={(total) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography color={'primary'}>
                        Invitaciones&nbsp;
                      </Typography>
                      <Typography> Generadas y No Generadas&nbsp;</Typography>
                      <Typography>({total})</Typography>
                    </div>
                  )}
                  colorArray={[colors.green[400], colors.red[500]]}
                  pending={pendingInvitations}
                  dataset={createdGeneratedData}
                />
              </Grid>
              <Grid item xs={12}>
                <ReportsWidget
                  title={'Reportes'}
                  pendingRRPPSalesReport={pendingRRPPSalesReport}
                  pendingOperationsReport={pendingOperationsReport}
                  pendingInvitationsReport={pendingInvitationsReport}
                  pendingTicketTypesReport={pendingTicketTypesReport}
                  pendingTicketCodesReport={pendingTicketCodesReport}
                  pendingRRPPOnlineReport={pendingRRPPOnlineReport}
                  pendingOrdersReport={pendingOrdersReport}
                  onRequestTicketTypesReport={handleRequestTicketTypesReport}
                  onRequestOperationsReport={handleRequestOperationsReport}
                  onRequestInvitationsReport={handleRequestInvitationsReport}
                  onRequestTicketCodesReport={handleRequestTicketCodesReport}
                  onRequestRRPPSalesReport={handleRRPPSalesReport}
                  onRequestRRPPOnlineReport={handleRRPPOnlineReport}
                  onRequestOrdersReport={handleRequestOrdersReport}
                />
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </div>
  )
}
