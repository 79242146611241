import React from 'react'
import { useDispatch } from 'react-redux'
import {
  resetActions,
  loadResellers,
  loadProducers,
  loadControllers
} from 'state/modules/organizers'
import { AppContainer } from '~/views/shared'
import { OrganizerIndexPage } from './OrganizerIndexPage'
import { OrganizerCreatePage } from './OrganizerCreatePage'
import { OrganizerUpdatePage } from './OrganizerUpdatePage'
import { BillingCredentialsPage } from './BillingCredentialsPage'
import { BillingSettingsPage } from './BillingSettingsPage'
import { InvitationIndexPage } from './Invitations/InvitationIndexPage'
import { OrganizerControllersPage } from './OrganizerControllersPage'
import { ControllerCreatePage } from './OrganizerControllersPage/ControllerCreatePage'
import { OrganizerResellersPage } from './OrganizerResellersPage'
import { ResellerCreatePage } from './OrganizerResellersPage/ResellerCreatePage'
import { OrganizerProducersPage } from './OrganizerProducersPage'
import { WithdrawalsPage } from './WithdrawalsPage'
import { ProducerCreatePage } from './OrganizerProducersPage/ProducerCreatePage'
import { AccountsPage } from './AccountsPage'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '~/views/routes'
import history from '~/config/history'

export const OrganizersPage = () => {
  const dispatch = useDispatch()

  const handleSuccessfulSubmit = async () => {
    await dispatch(resetActions())
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  const redirectToIndexController = async (organizerId) => {
    await dispatch(resetActions())
    history.push(
      ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.INDEX.replace(':id', organizerId)
    )
  }

  const redirectToIndexReseller = async (organizerId) => {
    await dispatch(resetActions())
    history.push(
      ROUTES.ADMIN.ORGANIZERS.RESELLERS.INDEX.replace(':id', organizerId)
    )
  }

  const redirectToIndexProducer = async (organizerId) => {
    await dispatch(resetActions())
    history.push(
      ROUTES.ADMIN.ORGANIZERS.PRODUCERS.INDEX.replace(':id', organizerId)
    )
  }

  const onSuccessControllers = (id) => {
    dispatch(loadControllers(id))
  }

  const onSuccessResellers = (id) => {
    dispatch(loadResellers(id))
  }

  const onSuccessProducers = (id) => {
    dispatch(loadProducers(id))
  }

  return (
    <AppContainer title='Organizadores'>
      <Switch>
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.INDEX}
          component={OrganizerIndexPage}
          exact
        />

        <Route
          path={ROUTES.ADMIN.ORGANIZERS.CREATE}
          render={(props) => (
            <OrganizerCreatePage
              {...props}
              onSuccess={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.UPDATE}
          render={(props) => (
            <OrganizerUpdatePage
              {...props}
              onSuccess={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.ORGANIZERS.INVITATIONS}
          render={(props) => <InvitationIndexPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.WITHDRAWALS}
          render={(props) => <WithdrawalsPage {...props} />}
        />
        <Route
          exact
          path={ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.INDEX}
          render={(props) => (
            <OrganizerControllersPage
              {...props}
              onSuccess={onSuccessControllers}
              onBack={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.CONTROLLERS.CREATE}
          render={(props) => (
            <ControllerCreatePage
              {...props}
              onSuccess={redirectToIndexController}
              onCancel={(id) => redirectToIndexController(id)}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.ORGANIZERS.RESELLERS.INDEX}
          render={(props) => (
            <OrganizerResellersPage
              {...props}
              onSuccess={onSuccessResellers}
              onBack={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.RESELLERS.CREATE}
          render={(props) => (
            <ResellerCreatePage
              {...props}
              onSuccess={redirectToIndexReseller}
              onCancel={(id) => redirectToIndexReseller(id)}
            />
          )}
        />
        <Route
          exact
          path={ROUTES.ADMIN.ORGANIZERS.PRODUCERS.INDEX}
          render={(props) => (
            <OrganizerProducersPage
              {...props}
              onSuccess={onSuccessProducers}
              onBack={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.PRODUCERS.CREATE}
          render={(props) => (
            <ProducerCreatePage
              {...props}
              onSuccess={redirectToIndexProducer}
              onCancel={(id) => redirectToIndexProducer(id)}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.BILLING.CREDENTIALS}
          render={(props) => <BillingCredentialsPage {...props} />}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.BILLING.SETTINGS}
          render={(props) => (
            <BillingSettingsPage
              {...props}
              onSuccess={handleSuccessfulSubmit}
            />
          )}
        />
        <Route
          path={ROUTES.ADMIN.ORGANIZERS.ACCOUNTS}
          render={(props) => <AccountsPage {...props} />}
        />
      </Switch>
    </AppContainer>
  )
}

export default OrganizersPage
