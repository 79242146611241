import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Email, Cancel } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  Button,
  CircularProgress,
  TextField,
  Chip,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import RichTextEditor from 'react-rte'
import { useStyles } from './CustomNotificationsForm.style'

const validationSchema = Yup.object().shape({
  Subject: Yup.string()
    .trim()
    .required('Es requerido ingresar el asunto')
    .max(250, "La cantidad máxima de caracteres es de '250'"),
  Emails: Yup.array()
    .min(1, 'Es requerido seleccionar al menos un email')
    .required('Es requerido seleccionar al menos un email'),
  Message: Yup.string()
    .transform((value) => value.replace(/(<([^>]+)>|&nbsp;)/gi, ''))
    .trim()
    .required('Es requerido ingresar un mensaje *')
    .max(10000, "La cantidad máxima de caracteres es de '10.000'")
})

export const CustomNotificationsForm = ({
  values,
  formikRef,
  actionText,
  pending,
  handleSubmit,
  openPreview,
  handleRemoveEmail,
  handleClearEmails,
  assignMessage,
  assignSubject,
  assignEditorValue,
  editorValue
}) => {
  const classes = useStyles()
  const [showHtmlMessage, setShowHtmlMessage] = useState(false)

  const toolbarConfig = {
    display: [
      'INLINE_STYLE_BUTTONS',
      'TEXT_ALIGNMENT_BUTTON',
      'BLOCK_TYPE_BUTTONS',
      'LINK_BUTTONS',
      'BLOCK_TYPE_DROPDOWN',
      'HISTORY_BUTTONS',
      'IMAGE_BUTTON'
    ],
    INLINE_STYLE_BUTTONS: [
      { label: 'Negrita', style: 'BOLD' },
      { label: 'Cursiva', style: 'ITALIC' },
      { label: 'Subrayado', style: 'UNDERLINE' },
      { label: 'Tachado', style: 'STRIKETHROUGH' },
      { label: 'Código', style: 'CODE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Título 1', style: 'header-one' },
      { label: 'Título 2', style: 'header-two' },
      { label: 'Título 3', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Viñetas', style: 'unordered-list-item' },
      { label: 'Numeración', style: 'ordered-list-item' }
    ]
  }

  const handleChangeClear = (event, value, action) => {
    if (action === 'clear') {
      handleClearEmails()
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formikRef}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={classes.formContainer}>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldRow}>
              <Autocomplete
                multiple
                open={false}
                onChange={handleChangeClear}
                id='tags-filled'
                style={{ paddingInline: 8, marginBlock: 8 }}
                options={values.Emails}
                value={values.Emails}
                freeSolo
                fullWidth
                disabled={values.Emails.length === 0}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option}
                      clickable={false}
                      deleteIcon={
                        <Cancel onMouseDown={() => handleRemoveEmail(option)} />
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    name='Emails'
                    onKeyPress={(e) => e.preventDefault()}
                    required
                    label={
                      (errors.Emails && touched.Emails && errors.Emails) ||
                      'Emails'
                    }
                  />
                )}
              />
            </div>
            <div className={classes.fieldRow}>
              <TextField
                label={
                  (errors.Subject && touched.Subject && errors.Subject) ||
                  'Asunto'
                }
                name='Subject'
                value={values.Subject}
                onChange={(e) => {
                  handleChange(e)
                  assignSubject(e.target.value)
                }}
                onBlur={handleBlur}
                error={touched.Subject && Boolean(errors.Subject)}
                fullWidth
                required
                size='small'
                variant='outlined'
                className={classes.field}
              />
            </div>
            <div className={classes.fieldRow}>
              <RichTextEditor
                value={editorValue}
                toolbarConfig={toolbarConfig}
                className={classes.fieldEditorText}
                placeholder={
                  (errors.Message && touched.Message && errors.Message) ||
                  'Mensaje *'
                }
                onChange={(value) => {
                  assignEditorValue(value)
                  handleChange({
                    target: {
                      name: 'Message',
                      value: value.toString('html')
                    }
                  })
                  assignMessage(value.toString('html'))
                }}
                onBlur={handleBlur}
              />
            </div>
            <FormControlLabel
              className={classes.containerCheckbox}
              control={
                <Checkbox
                  color='primary'
                  size='small'
                  checked={showHtmlMessage}
                  onChange={() => setShowHtmlMessage(!showHtmlMessage)}
                  name='ByPercent'
                />
              }
              label='Mostrar HTML'
            />
            {showHtmlMessage && (
              <div className={classes.fieldRow}>
                <TextField
                  label={
                    (errors.Message && touched.Message && errors.Message) ||
                    'Mensaje'
                  }
                  name='Message'
                  value={values.Message}
                  onChange={(e) => {
                    handleChange(e)
                    assignMessage(e.target.value)
                    const editorValue = RichTextEditor.createValueFromString(
                      e.target.value,
                      'html'
                    )
                    assignEditorValue(editorValue)
                  }}
                  onBlur={handleBlur}
                  error={touched.Message && Boolean(errors.Message)}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={10}
                  required
                  size='small'
                  variant='outlined'
                  className={classes.field}
                />
              </div>
            )}
          </div>
          <div className={classes.actionsContainer}>
            <Button
              onClick={openPreview}
              disabled={
                values.Message.replace(/(<([^>]+)>|&nbsp;)/gi, '').trim() ===
                  '' ||
                values.Subject.trim() === '' ||
                values.Emails.length === 0
              }
              color='secondary'
              variant='contained'>
              Vista Previa
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={pending}
              color='primary'
              variant='contained'
              startIcon={<Email />}>
              {pending ? (
                <CircularProgress size={24} color='secondary' />
              ) : (
                actionText
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default CustomNotificationsForm
