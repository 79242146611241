import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Typography,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { updateCommission } from '~/state/modules/accounts'
import { useStyles } from './WithdrawalsPage.style'
import { loadOrganizer } from '~/state/modules/organizers'
import { useHistory } from 'react-router-dom'
import { showSnackbarError, showSnackbarSuccess } from 'views/utils/snackbar'
import { ROUTES } from 'views/routes'
import { WithdrawalsFormDialog } from './WithdrawalsFormDialog'
import { WithdrawalsFormPesosDialog } from './WithdrawalsFormPesosDialog'

export const WithdrawalsPage = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id } = match.params
  const { organizer, pending } = useSelector((state) => state.organizers)
  const { pendingUpdate } = useSelector((state) => state.accounts)
  const [open, setOpen] = useState(false)
  const [openPesos, setOpenPesos] = useState(false)
  const [account] = useState()

  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      await dispatch(loadOrganizer(id))
    }

    fetchData().then()
  }, [dispatch, id])

  const handleUpdateCommission = async (updatedAccount) => {
    try {
      updatedAccount.Commission = updatedAccount.Commission / 100
      updatedAccount.Vat = updatedAccount.Vat / 100
      await dispatch(updateCommission(updatedAccount))
      showSnackbarSuccess('Se ha actualizado la comisión correctamente!')
      await dispatch(loadOrganizer(id))
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOpen(false)
    }
  }

  const handleBack = () => {
    history.push(ROUTES.ADMIN.ORGANIZERS.INDEX)
  }

  const handleOpenDialog = () => {
    setOpen(true)
  }

  const handleOpenPesosDialog = () => {
    setOpenPesos(true)
  }

  return (
    <>
      {organizer && (
        <Typography variant='h6' className={classes.eventTitle}>
          {`${organizer.Name} (${id})`}{' '}
        </Typography>
      )}
      <PaperContainer>
        {pending ? (
          <>
            <Typography variant='h5' className={classes.eventTitle}>
              Retiros
            </Typography>
            <CircularProgress size={24} color='secondary' />
          </>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography variant='h4' className={classes.eventTitle}>
                Retiros
              </Typography>
              <div>
                <Button
                  onClick={handleOpenPesosDialog}
                  color='primary'
                  variant='outlined'
                  style={{ textTransform: 'none' }}>
                  Retirar Fiat
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={handleOpenDialog}
                  color='primary'
                  variant='outlined'
                  style={{ textTransform: 'none' }}>
                  Retirar Cripto
                </Button>
              </div>
            </div>
            <Typography variant='h5' className={classes.eventTitle}>
              Tipo de Cambio:&nbsp;
              <Typography
                variant='h5'
                component='span'
                style={{ fontWeight: 'bold' }}>
                $1.113,90 ARS
              </Typography>
            </Typography>
            &nbsp;
            <Divider />
            <div
              className={classes.containerCard}
              onClick={() => {
                console.log('click')
              }}>
              <Typography variant='h5' className={classes.typography}>
                Hiciste un retiro el:
                <Typography
                  variant='h5'
                  component='span'
                  style={{ fontWeight: 'bold' }}>
                  &nbsp; 06 de Marzo 25'
                </Typography>
              </Typography>
              <div>
                <Typography
                  variant='h5'
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    color: 'darkorange'
                  }}>
                  USDT 495.85
                </Typography>
                <Typography
                  variant='h6'
                  style={{ color: '#797979', fontWeight: 'bold' }}>
                  PENDIENTE
                </Typography>
              </div>
            </div>
            <div
              className={classes.containerCard}
              onClick={() => {
                console.log('click')
              }}>
              <Typography variant='h5' className={classes.typography}>
                Hiciste un retiro el:
                <Typography
                  variant='h5'
                  component='span'
                  style={{ fontWeight: 'bold' }}>
                  &nbsp; 04 de Marzo 25'
                </Typography>
              </Typography>
              <div>
                <Typography
                  variant='h5'
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    color: 'darkgreen'
                  }}>
                  USDT 250.25
                </Typography>
                <Typography
                  variant='h6'
                  style={{ color: '#797979', fontWeight: 'bold' }}>
                  APROBADO
                </Typography>
              </div>
            </div>
            <div className={classes.actionsContainer}>
              <Button onClick={handleBack} color='primary'>
                Volver
              </Button>
            </div>
          </>
        )}
      </PaperContainer>
      <WithdrawalsFormDialog
        open={open}
        onClose={() => setOpen(false)}
        loading={pendingUpdate}
        handleSubmit={handleUpdateCommission}
        onCancel={() => setOpen(false)}
        values={account}
      />
      <WithdrawalsFormPesosDialog
        open={openPesos}
        onClose={() => setOpenPesos(false)}
        loading={pendingUpdate}
        handleSubmit={handleUpdateCommission}
        onCancel={() => setOpenPesos(false)}
        values={account}
      />
    </>
  )
}

export default WithdrawalsPage
