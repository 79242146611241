import React from 'react'
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  makeStyles,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

// Ejemplo de estilos (puedes ajustarlos a tu gusto o borrarlos)
const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'end',
    padding: 0,
    marginTop: -4,
    marginBottom: -12,
    marginRight: -4
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  },
  buttonWithdraw: {
    width: '-webkit-fill-available',
    fontSize: 'smaller'
  }
}))

export const WithdrawalsFormDialog = ({
  open,
  onClose,
  onCreate // callback para el botón "Crear"
}) => {
  const classes = useStyles()

  // Puedes guardar los valores de los campos en un estado local,
  // usar Formik, o simplemente mostrarlos sin lógica alguna.
  // Aquí lo dejamos todo “mockeado” para que sirva de preview.

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          overflowX: 'hidden'
        }
      }}>
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          className={classes.button}>
          <Close fontSize='small' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form className={classes.formContainer} style={{ width: 350 }}>
          <TextField label='Nombre' variant='outlined' fullWidth size='small' />
          <TextField
            label='Apellido'
            variant='outlined'
            fullWidth
            size='small'
          />
          <TextField
            label='Fecha de nacimiento'
            type='date'
            variant='outlined'
            fullWidth
            size='small'
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label='Número de identificación (CUIT/CUIL)'
            variant='outlined'
            fullWidth
            size='small'
          />
          <TextField
            label='Número de cuenta bancaria'
            variant='outlined'
            fullWidth
            size='small'
          />

          <div>
            <Button
              onClick={onCreate}
              variant='contained'
              color='primary'
              className={classes.buttonWithdraw}>
              Retirar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default WithdrawalsFormDialog
